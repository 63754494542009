import {
	dataActions,
	userActions,
	componentActions
} from 'store/actions.js';
import { loadPermissionsList } from './data.js';
import {
    getUserReports,
    getUserAutoPay,
    getUserMenu,
    getUserBusinessMenu,
	getFavorites,
    refreshUserData,
    refreshUserAccountBalance,
	saveUserName,
	saveUserEmail,
	saveUserLanguage,
	saveUserCountry,
	getUserMonthsAvailable
} from 'services/users/user.utilities';
import _findIndex from 'lodash/findIndex';
import { toast } from 'react-toastify';

export function loadPostLogin (userData) {
	return async (dispatch) => {
		dispatch(userActions.setData(userData));
		dispatch(componentActions.closeLoginModal());
		await dispatch(loadPermissionsList());
        await dispatch(getUserMenuData());
        await dispatch(getUserBusinessMenuData());
		await dispatch(loadUserFavorites());
		await dispatch(loadUserMonthsAvailable());
	};
};

export function refreshMe (businessId) {
	return async (dispatch) => {
		const user = await refreshUserData()
		dispatch(userActions.refreshData({ payload: user }));
		dispatch(getUserBusinessMenuData(businessId));
		await dispatch(loadUserFavorites());
	};
};

export function updateUserName (values) {
	return async (dispatch) => {
		let result = await saveUserName(values);
		toast[result.success ? 'success' : 'error'](result.message)
		if (result.success) {
			dispatch(updateUserProfileItems(values));
		}
	}
};

export function updateUserEmail (values) {
	return async (dispatch) => {
		let result = await saveUserEmail(values);
		toast[result.success ? 'success' : 'error'](result.message)
		if (result.success) {
			dispatch(updateUserProfileItems(values));
		}
	}
};

export function updateUserLanguage (values, language) {
	return async (dispatch) => {
		let result = await saveUserLanguage(values);
		toast[result.success ? 'success' : 'error'](result.message)
		if (result.success) {
			dispatch(updateUserProfileItems({language}));
		}
	}
};

export function updateUserCountry (values, country) {
	return async (dispatch) => {
		let result = await saveUserCountry(values);
		toast[result.success ? 'success' : 'error'](result.message)
		if (result.success) {
			dispatch(updateUserProfileItems({country}));
		}
	}
}

export function updateUserAccountBalance () {
	return async (dispatch) => {
		const result = await refreshUserAccountBalance();
		if (result.success) {
			dispatch(userActions.refreshBalance({
				balance: result.values.balance
			}));
		}
		else {
			toast.error(result.message);
		}
	};
};

export function updateUserProfileItems (data) {
	return (dispatch) => {
		if (data.firstName && data.lastName) {
			data.fullName = `${data.firstName} ${data.lastName}`;
		}
		dispatch(userActions.updateUserProfileItem(data));
	};
};

export function fetchUserReports () {
	return async (dispatch) => {
		const reports = await getUserReports();
		dispatch(userActions.setUserReports({
			reports
		}));
	};
};

export function setUserAutoPay () {
	return async (dispatch) => {
		const autoPays = await getUserAutoPay();
		dispatch(userActions.setAutoPays({
			autoPays
		}));
	};
};

export function loadUserFavorites () {
	return async (dispatch) => {
		const results = await getFavorites();
		if (results.success) {
			dispatch(userActions.updateFavorites({
				favorites: results.values
			}))
		} else {
			toast.error(results.message)
		}
	};
};

export function getUserMenuData () {
	return async (dispatch) => {
		const menu = await getUserMenu();
		if (Array.isArray(menu)) {
			dispatch(dataActions.saveUserMenu({ menu }));
		}
	};
};

export function getUserBusinessMenuData (selectBusinessId) {
	return (dispatch, getState) => {
		return getUserBusinessMenu().then(results => {
			if (results.success && results.values.length) {
				let selectedBusiness = getState().user.businessNav.selectedBusiness;
				let businessId = selectBusinessId;
				let businessNav = {
					businesses: results.values
				};
				if (!businessId && selectedBusiness === -1) {
					businessId = results.values[0].publicId;
				}
				dispatch(userActions.setUserBusinessNav({
					businessNav
				}));
				dispatch(selectBusinessNavData(businessId));
			}
			return Promise.resolve();
		});
	};
};

export function selectBusinessNavData (businessId) {
	return (dispatch, getState) => {
		let businessNav = getState().user.businessNav;
		let currentSelectedNav = businessNav.selectedBusiness;
		let menu = null;
		let selectedBusiness = -1;
		if (businessNav.businesses.length === 1) {
			selectedBusiness = 0;
			menu = businessNav.businesses[0].menu;
		}
		else {
			if (!businessId) {
				if (businessNav.selectedBusinessId === -1) {
					if (businessNav.businesses.length) {
						menu = businessNav.businesses[0].menu;
						selectedBusiness = 0;
					}
				}
			}
			else {
				let index = _findIndex(businessNav.businesses, {publicId: businessId});
				if (index > -1) {
					selectedBusiness = index;
					menu = businessNav.businesses[index].menu;
				}
				else {
					if (businessNav.selectedBusinessId === -1) {
						if (businessNav.businesses.length) {
							menu = businessNav.businesses[0].menu;
							selectedBusiness = 0;
						}
					}
				}
			}
		}
		if (selectedBusiness !== currentSelectedNav || currentSelectedNav === -1) {
			dispatch(userActions.selectBusinessNav({
				businessNav: {
					selectedBusiness
				}
			}));
		}
	};
};

export function loadUserMonthsAvailable () {
	return async (dispatch) => {
		const monthsAvailable = await getUserMonthsAvailable();
		dispatch(userActions.setUserMonthsAvailable({
			monthsAvailable
		}));
	};
};

import { businessActions } from "store/actions";
import {
    getEditablePoll,
    getEditableItem,
    getBusinessTags,
    getBusinessEmployeeInvitesList,
    getBusinessEmployeesList,
    getBusinessContacts,
    submitUpdateBusinessField,
    getBusinessManageDetails,
    getCurrentBusinessRoles,
    getBusinessRoleTemplates,
    getOtherBusinessRoles,
    submitUpdateBusinessCategory,
    submitCreateBusinessCategory,
    getBusinessCategoriesList,
    doDeleteBusinessWebsite,
    doAddBusinessWebsite
} from 'services/businesses';
import { refreshMe } from './user.js';
import { toast } from 'react-toastify';


/* BUSINESS CATEGORIES */
export function loadBusinessCategories (businessId, activeOnly) {
	return async (dispatch) => {
		const result = await getBusinessCategoriesList(businessId, activeOnly);
        if (result.success) {
            dispatch(businessActions.saveCategoryList({
				categories: result.values
			}));
        } else {
            toast.error(result.message);
        }
	};
};

export function saveNewBusinessCategory (publicId, values) {
	return async (dispatch) => {
		const result = await submitCreateBusinessCategory(publicId, values);
        const msgType = result.success ? 'success' : 'error';
        toast[msgType](result.message);
        return result;
	};
};

export function saveEditBusinessCategory (publicId, values) {
	return async (dispatch) => {
		const result = await submitUpdateBusinessCategory(publicId, values);
		const msgType = result.success ? 'success' : 'error';
        toast[msgType](result.message);
        return result;
	};
};

/* BUSINESS ROLES */
export function getBusinessRoles (businessId) {
	return async (dispatch) => {
		const result = await getCurrentBusinessRoles(businessId);
        if (result.success) {
            dispatch(businessActions.saveBusinessRoles({
				roles: result.values
			}));
        }
    
        return result;
	};
};

export function getCopyableBusinessRoles (businessId) {
	return async (dispatch) => {
		const result = await getOtherBusinessRoles(businessId);
        if (result.success) {
            dispatch(businessActions.saveBusinessOtherRoles({
				businesses: result.values
			}));
        }
        return result;
	};
};

export function getAllRoleTemplates () {
	return async (dispatch) => {
		const result = await getBusinessRoleTemplates();
        if (result.success) {
            dispatch(businessActions.saveBusinessRoleTemplates({
				templates: result.values
			}));
        }
	};
};

/* MANAGE BUSINESS ACCOUNT */

export function getBusinessDetails (publicId) {
	return async (dispatch) => {
		const result = await getBusinessManageDetails(publicId);
		if (result.success) {
            dispatch(businessActions.saveBusinessAccount({
				account: result.values
			}));
        }
	};
};

export function updateBusinessName (publicId, name) {
	return async (dispatch) => {
		const result = await submitUpdateBusinessField(publicId, 'name', name);
        if (result.success) {
            dispatch(businessActions.updateBusinessName({
				name: result.values
			}));
            toast.success('Name has been updated.');
            dispatch(refreshMe(publicId));
        }
        else {
            toast.error(result.message);
        }
	};
};

// export function updateBusinessPaypal (publicId, paypal) {
// 	return async (dispatch) => {
// 		const result = await submitUpdateBusinessField(publicId, 'paypal', paypal);
// 		if (result.success) {
//             dispatch(businessActions.updateBusinessPaypal({
// 				paypalAccount: result.values
// 			}));
//             toast.success('Paypal account has been updated.');
//         }
//         else {
//             toast.error(result.message);
//         }
// 	};
// };

export function updateBusinessDefaultPrice (publicId, price) {
	return async (dispatch) => {
		const result = await submitUpdateBusinessField(publicId, 'defaultPrice', price);
        if (result.success) {
            dispatch(businessActions.updateBusinessDefaultPrice({
				defaultPrice: result.values
			}));
            toast.success('Default price has been updated.');
        }
        else {
            toast.error(result.message);
        }
	};
};

export function updateBusinessLanguages (publicId, languages) {
	return async (dispatch) => {
		const result = await submitUpdateBusinessField(publicId, 'languages', languages);
        if (result.success) {
            dispatch(businessActions.updateBusinessLanguages({
				languages: result.values
			}));
            toast.success('Languages have been updated.');
        }
        else {
            toast.error(result.message);
        }
	};
};

export function updateBusinessCountry (publicId, country) {
	return async (dispatch) => {
		const result = await submitUpdateBusinessField(publicId, 'country', country);
        if (result.success) {
            dispatch(businessActions.updateBusinessCountry({
				country: result.values
			}));
            toast.success('Country has been updated.');
        }
        else {
            toast.error(result.message);
        }
	};
};

export function updateBusinessHandle (publicId, handle) {
	return async (dispatch) => {
		const result = await submitUpdateBusinessField(publicId, 'handle', handle);
        if (result.success) {
            dispatch(businessActions.updateBusinessHandle({
				handle: result.values
			}));
            toast.success('Handle has been updated.');
        }
        else {
            toast.error(result.message);
        }
	};
};


/* BUSINESS CONTACTS */
export function loadBusinessContacts (businessPublicId) {
	return async (dispatch) => {
		const result = await getBusinessContacts(businessPublicId);
        if (result.success) {
            dispatch(businessActions.saveBusinessContacts({
				contacts: result.values
			}));
        }
        else {
            toast.error(result.message);
        }
	};
};

/* BUSINESS EMPLOYEES */
export function loadBusinessEmployees (businessId) {
	return async (dispatch) => {
		const result = await getBusinessEmployeesList(businessId);
        if (result.success) {
            dispatch(businessActions.saveBusinessEmployeesList({
				employees: result.value
			}));
        }
        else {
            toast.error(result.message);
        }
	};
};

export function loadBusinessEmployeeInvites (businessId) {
	return async (dispatch) => {
		const result = await getBusinessEmployeeInvitesList(businessId);
        if (result.success) {
            dispatch(businessActions.saveBusinessEmployeeInvitesList({
				pendingInvites: result.value
			}));
        }
        else {
            toast.error(result.message);
        }
	};
};

/* TAGS */
export function loadBusinessTags (businessId) {
	return async (dispatch) => {
		const result = await getBusinessTags(businessId);
        if (result.success) {
            dispatch(businessActions.saveBusinessTags({
				tags: result.values
			}));
        }
        else {
            toast.error(result.message);
        }
	};
};

export function setMyBusinessName (name, publicId) {
	return (dispatch) => {
        dispatch(businessActions.setBusinessName({
            vals: {
				name,
				publicId
			}
        }));
	};
};

/* BUSINESS WEBSITES */
export function removeBusinessWebsite (publicId, uuid) {
	return async (dispatch) => {
		const result = await doDeleteBusinessWebsite(publicId, uuid);
        if (result.success) {
            toast.success('Website has been removed.');
            dispatch(getBusinessDetails(publicId, true));
        }
        else {
            toast.error(result.message);
        }
	};
};

export function addBusinessWebsite (publicId, url) {
	return async (dispatch) => {
		const result = await doAddBusinessWebsite(publicId, url);
        if (result.success) {
            toast.success('Website has been added.');
            dispatch(getBusinessDetails(publicId, true));
        }
        else {
            toast.error(result.message);
        }
	};
};


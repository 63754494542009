import { fetch, fetchPost, fetchPut, fetchDelete } from 'utilities/utilities';
import { DATA_PATH, PUB_PATH } from 'utilities/globals';
// import store from 'app/store';

export const minPasswordLength = 8;
export const maxPasswordLength = 20;

/* user calls */
export function updateUserPassword (oldPassword, newPassword) {
	return fetchPost(`${DATA_PATH}/employees/changePassword`, {
		oldPassword,
		newPassword
	});
};

export function isValidPassword (password) {
	let low = /[a-z]/;
	let high = /[A-Z]/;
	let numbers = /[0-9]+/;
	let symbols = /[!@#$%&,.<>?\-_+]/;
	let other = /[^\w!@#$%&,.<>?_+]/;
	let tests = [low, high, numbers, symbols];
	return password && password.length >= minPasswordLength && password.length <= maxPasswordLength && tests.every(test => {
		return test.test(password);
	}) && !other.test(password);
};

export function getFavorites () {
	return fetch(`${DATA_PATH}/users/favorites`);
};

export function addFavorite (businessId) {
	return fetchPost(`${DATA_PATH}/users/favorites/${businessId}`, null);
};

export function deleteFavorite (businessId) {
	return fetchDelete(`${DATA_PATH}/users/favorites/${businessId}`);
};


export function checkEmailAvailable (email) {
	return fetch(`${PUB_PATH}/users/checkEmailAvailable/${email}`);
};

export function registerNewUser (user) {
	return fetchPost(`${PUB_PATH}/users/register`, user);
};

export function sendConfirmationEmail (email) {
	return fetch(`${PUB_PATH}/users/confirmationEmail/${email}`);
};

export function submitConfirmationCode (code) {
	return fetch(`${PUB_PATH}/users/confirmEmail/${code}`);
};

export function changeUserPassword (formValues) {
	return fetchPut(`${DATA_PATH}/users/changePassword`, formValues);
};

export function forgotPassword (formValues) {
	return fetchPost(`${PUB_PATH}/users/forgotPassword`, formValues);
};

export function resetPassword (formValues) {
	return fetchPost(`${PUB_PATH}/users/resetPassword`, formValues);
};

export function saveUserName (formValues) {
	return fetchPut(`${DATA_PATH}/users/name`, formValues);
};

export function saveUserEmail (formValues) {
	return fetchPut(`${DATA_PATH}/users/email`, formValues);
};

export function saveUserLanguage (formValues) {
	return fetchPut(`${DATA_PATH}/users/language`, formValues);
};

export function saveUserCountry (formValues) {
	return fetchPut(`${DATA_PATH}/users/country`, formValues);
};

export function getUserReports () {
	return fetch(`${DATA_PATH}/users/transactions/totals`);
};

export function getUserAutoPay () {
	return fetch(`${DATA_PATH}/users/autoPay`);
};

export function getUserActivity (month) {
	return fetchPost(`${DATA_PATH}/users/transactions/byMonth`, month);
};

export function getUserMonthsAvailable () {
	return fetch(`${DATA_PATH}/users/transactions/monthsAvailable`);
};

// data = {id, maxValue}
export function updateAutoPay (data) {
	return fetchPut(`${DATA_PATH}/users/autoPay/update`, data);
};

export function changeCreditLimit (fields) {
	return fetchPut(`${DATA_PATH}/users/creditLimit`, fields);
};

export function getUserMenu () {
	return fetch(`${PUB_PATH}/menus/user`);
};

export function getUserBusinessMenu () {
	return fetch(`${DATA_PATH}/menus/user/business`);
};

export function refreshUserData () {
	return fetch(`${DATA_PATH}/users/me`);
};

export function refreshUserAccountBalance () {
	return fetch(`${DATA_PATH}/users/balance`);
}

export function getIsFirstCPsPurchase () {
	return fetch(`${DATA_PATH}/users/CPs/first`);
};

// export function initiatePaypalTransaction (data) {
// 	return fetchPost(`${DATA_PATH}/users/CPs/paypal/init`, data);
// };

// export function updatePaypalTransactionComplete (data) {
// 	return fetchPut(`${DATA_PATH}/users/CPs/paypal/complete`, data);
// };

// export function updatePaypalTransactionCancelled (data) {
// 	return fetchPut(`${DATA_PATH}/users/CPs/paypal/cancel`, data);
// }

export function initiateStripeTransaction (data) {
	return fetchPost(`${DATA_PATH}/users/CPs/stripe/init`, data);
};

export function updateStripeTransactionComplete (data) {
	return fetchPut(`${DATA_PATH}/users/CPs/stripe/complete`, data);
};

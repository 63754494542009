import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
/*
const newCategory = {
	name: '',
	defaultPrice: -1,
	active: true
};

const newRole = {
	name: '',
	description: '',
	ranking: 10,
	permissions: [],
	categoryPermissions: {},
	id: ''
};
*/

export const businesses = createSlice({
	name: 'business',
	initialState: {
		publicId: '',
        name: '',
        businessAccount: {},
        contacts: [],
        categories: [],
        employees: [],
        pendingInvites: [],
        websites: [],
        tags: [],
        /* editCategoryDialog: {
            isEdit: false,
            isSaving: false,
            props: {
                category: null,
                open: false
            }
        },
        editEmployeeDialog: {
            isSaving: false,
            props: {
                open: false,
                employee: null,
                businessId: null
            }
        }, */
        roles: {
            business: [],
            templates: [],
            otherBusinesses: {}
            /* importRolesDialog: {
                props: {
                    open: false
                }
            },
            showEmployeesDialog: {
                props: {
                    open: false,
                    employees: [],
                    role: '' // name of the role
                }
            },
            editRoleDialog: {
                isEdit: false,
                isSaving: false,
                props: {
                    open: false,
                    role: null
                }
            } */
        }
	},
	reducers: {
		/* openConfirmDialog: (state, action) => {
			state.confirmDialog = {
				open: true,
				options: action.payload.options
			}
		}, */
        setBusinessName: (state, action) => {
            return {
				...state,
				...action.payload.vals
			};
        },
		saveCategoryList: (state, action) => {
            state.categories = action.payload.categories;
            return state;
        },
        saveBusinessRoles: (state, action) => {
            return {
				...state,
				roles: {
					...state.roles,
					business: action.payload.roles
				}
			};
        },
        saveBusinessRoleTemplates: (state, action) => {
            return {
				...state,
				roles: {
					...state.roles,
					templates: action.payload.templates
				}
			};
        },
        saveBusinessOtherRoles: (state, action) => {
            return {
				...state,
				roles: {
					...state.roles,
					otherBusinesses: action.payload.businesses
				}
			};
        },
        saveBusinessAccount: (state, action) => {
            state.businessAccount = action.payload.account;
            return state;
        },
        updateBusinessName: (state, action) => {
            return {
				...state,
				businessAccount: {
					...state.businessAccount,
					name: action.payload.name
				}
			};
        },
        // updateBusinessPaypal: (state, action) => {
        //     return {
		// 		...state,
		// 		businessAccount: {
		// 			...state.businessAccount,
		// 			paypalAccount: action.payload.paypalAccount
		// 		}
		// 	};
        // },
        updateBusinessDefaultPrice: (state, action) => {
            return {
				...state,
				businessAccount: {
					...state.businessAccount,
					defaultPrice: action.payload.defaultPrice
				}
			};
        },
        updateBusinessLanguages: (state, action) => {
            return {
				...state,
				businessAccount: {
					...state.businessAccount,
					languages: action.payload.languages
				}
			};
        },
        updateBusinessCountry: (state, action) => {
            return {
				...state,
				businessAccount: {
					...state.businessAccount,
					country: action.payload.country
				}
			};
        },
        updateBusinessHandle: (state, action) => {
            return {
				...state,
				businessAccount: {
					...state.businessAccount,
					handle: action.payload.handle
				}
			};
        },
        saveBusinessContacts: (state, action) => {
            state.contacts = action.payload.contacts;
            return state;
        },
        updateBusinessReferrer: (state, action) => {
            return {
				...state,
				businessAccount: {
					...state.businessAccount,
					referrer: action.payload.referrer
				}
			};
        },
        saveBusinessEmployeesList: (state, action) => {
            return {
				...state,
				employees: action.payload.employees
			};
        },
        saveBusinessEmployeeInvitesList: (state, action) => {
            state.pendingInvites = action.payload.pendingInvites;
            return state;
        },
        saveBusinessTags: (state, action) => {
            state.tags = action.payload.tags;
            return state;
        },
        addBusinessTags: (state, action) => {
            let tags = state.tags;
			let allTags = action.tags.concat(tags);
            state.tags = allTags;
            return state;
        },
        removeBusinessTags: (state, action) => {
            let tags = _.cloneDeep(state.tags);
			let index = _.findIndex(tags, {_id: action.payload.tagId});
			if (index > -1) {
				tags.splice(index, 1);
			}
			state.tags = tags;
            return state;
        }
	}
});

export const {
	setBusinessName,
    saveCategoryList,
    saveBusinessRoles,
    saveBusinessRoleTemplates,
    saveBusinessOtherRoles,
    saveBusinessAccount,
    updateBusinessName,
    updateBusinessPaypal,
    updateBusinessDefaultPrice,
    updateBusinessLanguages,
    updateBusinessCountry,
    updateBusinessHandle,
    saveBusinessContacts,
    saveBusinessEmployeesList,
    saveBusinessEmployeeInvitesList,
    saveBusinessTags,
    addBusinessTags,
    removeBusinessTags
} = businesses.actions

export default businesses.reducer

import { createSlice } from '@reduxjs/toolkit'
import authService from 'services/auth'

const initState = {
	isLoggedIn: false,
	_id: null,
	publicId: null,
	firstName: '',
	lastName: '',
	fullName: '',
	email: '',
	language: {},
	country: {},
	userType: 'customer',
	creditBalance: 0,
	creditLimit: 0,
	businesses: [],
	favorites: [],
	businessNav: {
		selectedBusiness: -1,
		businesses: []
	},
	roles: [],
	lowestRole: 10,
	permissions: [],
	reports: {
		lifetime: {},
		monthly: [],
		monthsAvailable: []
	},
	autoPays: []
}

export const user = createSlice({
    name: 'user',
    initialState: initState,
    reducers: {
        setData: (state, action) => {
			const endValue = {
				...initState,
				...action.payload
			};
			return endValue
		},
        refreshData: (state, action) => {
			return {
				...state,
				...action.payload
			}
		},
		refreshBalance: (state, action) => {
			state.creditBalance = action.payload.balance
		},
		removeData: (state, action) => {
			return {
				...initState
			};
		},
		logout: (state, action) => {
			authService.logout();
			return {
				...initState
			};
		},
		passwordUpdate: (state, action) => {
			state.password = {
				expired: false,
				expiresIn: null
			}
			return state;
		},
		updateFavorites: (state, action) => {
			state.favorites = action.payload.favorites
			return state;
		},
		setAutoPays: (state, action) => {
			state.autoPays = action.payload.autoPays;
			return state;
		},
		setUserBusinessNav: (state, action) => {
			state.businessNav = {
				...state.businessNav,
				...action.payload.businessNav
			}
			return state;
		},
		selectBusinessNav: (state, action) => {
			state.businessNav = {
				...state.businessNav,
				...action.payload.businessNav
			}
			return state;
		},
		updateUserProfileItem: (state, action) => {
			const keys = Object.keys(action.payload);
			keys.forEach(key => {
				state[key] = action.payload[key];
			});
			return state;
			
		},
		setUserReports: (state, action) => {
			return {
				...state,
				reports: {
					...action.payload.reports
				}
			}
		},
		setUserMonthsAvailable: (state, action) => {
			state.reports.monthsAvailable = action.payload.monthsAvailable;
			return state;
		}

    }
})

export const {
	setData,
	refreshData,
	refreshBalance,
	removeData,
	logout,
	passwordUpdate,
	updateFavorites,
	setAutoPays,
	setUserBusinessNav,
	selectBusinessNav,
	updateUserProfileItem,
	setUserReports,
	setUserMonthsAvailable
} = user.actions

export default user.reducer
